<template>
  <ion-row>
    <ion-col size="9" class="row-align-center">
      <ion-label
        class="title-part-form-driver"
        @click="onChangeToggleComment(!toggleComment)"
        >Commentaire</ion-label
      >
      <ion-button
        v-if="comments"
        color="danger"
        size="small"
        class="ion-margin-start"
        @click="onChangeComments()"
      >
        <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col class="padding-0 justify-content-end">
      <ion-toggle
        @ionChange="onChangeToggleComment($event.target.checked)"
        :checked="toggleComment"
        mode="md"
        color="dark"
      ></ion-toggle>
    </ion-col>
  </ion-row>

  <ion-row v-show="toggleComment">
    <ion-col>
      <ion-textarea
        name="comments"
        class="textarea-form-driver"
        rows="4"
        maxlength="2000"
        @ionChange="onChangeComments($event.target.value)"
        :value="comments"
        :disabled="oldRentalCouponSending"
        :class="{
          'limit-reached': comments && comments.length >= 2000,
        }"
      ></ion-textarea>
      <div
        v-if="comments"
        class="ion-text-end ion-margin-end"
        :class="comments.length >= 2000 ? 'text-danger' : 'color-green'"
      >
        {{ comments.length }} / 2000
      </div>
    </ion-col>
  </ion-row>

  <attachments
    v-show="toggleComment"
    v-model:attachments="filteredAttachments"
    v-model:waiting-files="waitingFiles"
    v-model:attachments-to-delete="attachmentsToDelete"
    v-model:attachments-to-update="attachmentsToUpdate"
    :type-vue="typeVue"
    :status="status"
    :files-too-big="filesTooBig"
    kind="comments"
  />

  <hr />

  <ion-row class="flex-item">
    <ion-col mode="ios" class="align-segment-contact-form-driver">
      <ion-segment
        v-if="
          supervisorsBuilder &&
          supervisorsBuilder.length > 0 &&
          supervisorsBuilder[0].id
        "
          :value="segmentContact"
          @ionChange="onChangeSegmentContact($event.target.value)"
          :disabled="oldRentalCouponSending"
      >
        <ion-segment-button
            class="width-segment-form-driver"
            value="available"
            :disabled="!builder_id"
        >
          <ion-label class="fs-12">Sélectionnez un contact</ion-label>
        </ion-segment-button>
        <ion-segment-button class="width-segment-form-driver" value="new">
          <ion-label class="fs-12">Nouveau contact</ion-label>
        </ion-segment-button>
      </ion-segment>
      <ion-label class="title-part-form-driver" v-else>
        Nouveau contact
      </ion-label>
    </ion-col>
  </ion-row>

  <ion-row class="mb-5">
    <ion-col>
      <ion-select
        class="mt-10"
        v-if="segmentContact === 'available'"
        @ionChange="onChangeContactId($event.target.value)"
        :value="contact_id"
        placeholder="Contact non-renseigné"
        :interface-options="{ subHeader: 'Sélectionnez un contact' }"
        ok-text="Valider"
        cancel-text="Annuler"
        :disabled="oldRentalCouponSending"
      >
        <ion-select-option
            v-for="supervisor in supervisorsBuilder"
            :value="supervisor.id"
            :key="supervisor.id"
        >{{ supervisor.name }}</ion-select-option
        >
      </ion-select>

      <ion-item v-else class="item-floating mr-0 ml-0">
        <ion-label position="floating">Contact</ion-label>
        <ion-input
            name="contact_name"
            mode="md"
            :disabled="oldRentalCouponSending"
            :required="
          status === 'send' && typeClient && typeClient === coupon_company
        "
            @ionChange="onChangeContactName($event.target.value)"
            :value="contact_name"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row
      class="mb-5"
  >
    <ion-col>
      <ion-item class="item-floating mr-0 ml-0">
        <ion-label position="floating">Email</ion-label>
        <ion-input
            name="contact_email"
            mode="md"
            type="email"
            inputmode="email"
            @ionChange="onChangeContactEmail($event.target.value)"
            :value="contact_email"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonLabel,
  IonToggle,
  IonTextarea,
  IonSegment,
  IonSegmentButton,
  IonSelectOption,
  IonSelect,
  IonInput,
  IonItem,
  IonIcon,
  IonButton,
} from '@ionic/vue';
import utils from '@/services/utils/Utils';
import Attachments from '@/components/driver/rentalCoupon/components/Attachments';
import { getInfo } from '@/services/users';
import { trashOutline } from 'ionicons/icons';

export default {
  name: 'Options',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonToggle,
    IonTextarea,
    IonSegment,
    IonSegmentButton,
    IonSelectOption,
    IonSelect,
    IonInput,
    IonItem,
    IonIcon,
    IonButton,
    Attachments,
  },
  props: [
    'toggleMachineCounter',
    'toggleComment',
    'pricingFuel',
    'machine_counter_start',
    'machine_counter_end',
    'comments',
    'oldRentalCouponSending',
    'typeMission',

    'segmentContact',
    'contact_id',
    'supervisorsBuilder',
    'typeClient',
    'coupon_company',
    'contact_name',
    'builder_id',
    'contact_email',
    'contact_phone',
    'status',
    'oldContactId',
    'oldContactEmail',

    'attachments',
    'typeVue',
    'filesTooBig',
  ],

  setup() {
    return {
      trashOutline,
    };
  },

  data() {
    return {
      numberAreaWorksite: utils.generateArray(10),
      waitingFiles: [],
      attachmentsToDelete: [],
      attachmentsToUpdate: {},
    };
  },

  mounted() {
    // only for "TARN POMPAGE", init comments with default value
    getInfo().then(res => {
      if (
        res &&
        res.userData &&
        res.userData.company_id &&
        // "TARN POMPAGE" id
        res.userData.company_id === 66
      ) {
        this.onChangeComments(
          'Heure d’arrivée chantier : \nHeure début pompage : \nHeure fin pompage : \nHeure départ chantier :',
        );
        this.onChangeToggleComment(true);
      }
    });
  },

  methods: {
    onChangeContactEmail(value) {
      this.$emit('update:contact_email', value);
    },
    onChangeSegmentContact(value) {
      this.$emit('update:segmentContact', value);
      this.updateSegmentContact(value);
    },
    onChangeContactId(value) {
      this.$emit('update:contact_id', value);
      this.updateContactEmail(value);
    },
    onChangeContactName(value) {
      this.$emit('update:contact_name', value);
    },
    onChangeToggleMachineCounter(value) {
      this.$emit('update:toggleMachineCounter', value);
    },
    onChangeToggleComment(value) {
      this.$emit('update:toggleComment', value);
    },
    onChangeFuel(value) {
      this.$emit('update:pricingFuel', value);
    },
    onChangeMachineCounterStart(value) {
      this.$emit('update:machine_counter_start', value);
    },
    onChangeMachineCounterEnd(value) {
      this.$emit('update:machine_counter_end', value);
    },
    onChangeComments(value) {
      this.$emit('update:comments', value);
    },

    updateContactEmail(contactIdValue) {
      if (
          this.typeMission !== 'reservation' &&
          this.coupon_company !== this.typeClient
      ) {
        return;
      }

      for (const key in this.supervisorsBuilder) {
        if (this.supervisorsBuilder[key].id === contactIdValue) {
          if (
              this.typeVue !== 'create' &&
              this.supervisorsBuilder[key].id === this.oldContactId
          ) {
            this.$emit('update:contact_email', this.oldContactEmail);
            return;
          }
          this.$emit(
              'update:contact_email',
              this.supervisorsBuilder[key].email,
          );
          this.$emit(
              'update:contact_phone',
              this.supervisorsBuilder[key].phone,
          );
          return;
        }
      }
    },

    updateSegmentContact(segmentValue) {
      if (
          this.typeMission !== 'reservation' &&
          this.coupon_company !== this.typeClient
      ) {
        return;
      }

      if (segmentValue === 'new') {
        this.$emit('update:contact_email', '');
        this.$emit('update:contact_phone', null);
        return;
      }

      // if (this.segmentSourceContact === 'available')
      for (const key in this.supervisorsBuilder) {
        if (this.supervisorsBuilder[key].id === this.contact_id) {
          if (
              this.typeVue !== 'create' &&
              this.supervisorsBuilder[key].id === this.oldContactId
          ) {
            this.$emit('update:contact_email', this.oldContactEmail);
            return;
          }
          this.$emit(
              'update:contact_email',
              this.supervisorsBuilder[key].email,
          );
          this.$emit(
              'update:contact_phone',
              this.supervisorsBuilder[key].phone,
          );
          return;
        }
      }
    },

    resetFilesInformation() {
      this.waitingFiles = [];
      this.attachmentsToDelete = [];
      this.attachmentsToUpdate = {};
    },

    getWaitingFiles() {
      return this.waitingFiles;
    },

    getAttachmentsToDelete() {
      return this.attachmentsToDelete;
    },

    getAttachmentsToUpdate() {
      return this.attachmentsToUpdate;
    },
  },

  computed: {
    filteredAttachments: {
      get() {
        if (!this.attachments) return [];

        return this.attachments.filter(a => a.kind === 'comments');
      },

      set(newVal) {
        this.$emit('update:attachments', newVal);
      },
    },
  },
};
</script>


<style scoped>
  hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .flex-item {
    display: flex;
    justify-content: space-around;
  }

  .center-element {
    display: flex;
    align-items: center;
  }

  ion-select {
    border: 1px solid black;
    border-radius: 4px;
  }
  
  .mr-0 {
    margin-right: 0;
  }
  .ml-0 {
    margin-left: 0;
  }
</style>
